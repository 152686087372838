<template>
  <v-card style="max-width: 750px" elevation="0" class="mx-auto mt-4 pa-6 text-center">
    <h3 class="adsimple-221148541">Was ist YouTube?</h3>
    <p>Wir haben auf unserer Website YouTube-Videos eingebaut. So können wir Ihnen interessante Videos direkt auf unserer Seite präsentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist. Betrieben wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Website eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser automatisch mit den Servern von YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten übertragen. Für die gesamte Datenverarbeitung im europäischen Raum ist Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich.</p>
    <p>Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet werden, warum wir YouTube-Videos eingebunden haben und wie Sie Ihre Daten verwalten oder löschen können.</p>
    <p>Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. Über die letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos auf unserer Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut haben.</p>
    <h3 class="adsimple-221148541">Warum verwenden wir YouTube-Videos auf unserer Website?</h3>
    <p>YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bemüht, Ihnen die bestmögliche User-Erfahrung auf unserer Webseite zu bieten. Und natürlich dürfen interessante Videos dabei nicht fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben unseren Texten und Bildern weiteren hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die eingebetteten Videos leichter gefunden. Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google – dank der gesammelten Daten – diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere Angebote interessieren.</p>
    <h3 class="adsimple-221148541">Welche Daten werden von YouTube gespeichert?</h3>
    <p>Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein Cookie, das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann YouTube Ihre Interaktionen auf unserer Webseite meist mithilfe von Cookies Ihrem Profil zuordnen. Dazu zählen Daten wie Sitzungsdauer, Absprungrate, ungefährer Standort, technische Informationen wie Browsertyp, Bildschirmauflösung oder Ihr Internetanbieter. Weitere Daten können Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten auf YouTube sein.</p>
    <p>Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet sind, speichert Google Daten mit einer eindeutigen Kennung, die mit Ihrem Gerät, Browser oder App verknüpft sind. So bleibt beispielsweise Ihre bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten können nicht gespeichert werden, da weniger Cookies gesetzt werden.</p>
    <p>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits Cookies, die ohne angemeldetes YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit angemeldetem Account gesetzt werden. Die Liste kann keinen Vollständigkeitsanspruch erheben, weil die Userdaten immer von den Interaktionen auf YouTube abhängen.</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> YSC<br />
      <strong class="adsimple-221148541">Wert:</strong> b9-CV6ojI5Y221148541-1<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu speichern.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach Sitzungsende</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> PREF<br />
      <strong class="adsimple-221148541">Wert:</strong> f1=50000000<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 8 Monaten</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> GPS<br />
      <strong class="adsimple-221148541">Wert:</strong> 1<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um den GPS-Standort zu tracken.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 30 Minuten</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> VISITOR_INFO1_LIVE<br />
      <strong class="adsimple-221148541">Wert:</strong> 95Chz8bagyU<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu schätzen.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 8 Monaten</p>
    <p>Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto angemeldet sind:</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> APISID<br />
      <strong class="adsimple-221148541">Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7221148541-<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> CONSENT<br />
      <strong class="adsimple-221148541">Wert:</strong> YES+AT.de+20150628-20-0<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen und Userdaten vor unbefugten Angriffen zu schützen.<br />
      <strong class="adsimple-221148541">Ablaufdatum: </strong>nach 19 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> HSID<br />
      <strong class="adsimple-221148541">Wert:</strong> AcRwpgUik9Dveht0I<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu können.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> LOGIN_INFO<br />
      <strong class="adsimple-221148541">Wert:</strong> AFmmF2swRQIhALl6aL…<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> In diesem Cookie werden Informationen über Ihre Login-Daten gespeichert.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> SAPISID<br />
      <strong class="adsimple-221148541">Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es Ihren Browser und Ihr Gerät eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre Interessen zu erstellen.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> SID<br />
      <strong class="adsimple-221148541">Wert:</strong> oQfNKjAsI221148541-<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie speichert Ihre Google-Konto-ID und Ihren letzten Anmeldezeitpunkt in digital signierter und verschlüsselter Form.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> SIDCC<br />
      <strong class="adsimple-221148541">Wert:</strong> AN0-TYuqub2JOcDTyL<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise gesehen haben.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 3 Monaten</p>
    <h3 class="adsimple-221148541">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den Google-Servern gespeichert. Die meisten dieser Server befinden sich in Amerika. Unter <a class="adsimple-221148541" href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank" rel="noopener noreferrer nofollow" >https://www.google.com/about/datacenters/inside/locations/?hl=de</a> sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten sind auf den Servern verteilt. So sind die Daten schneller abrufbar und vor Manipulation besser geschützt.</p>
    <p>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten können Sie jederzeit löschen, andere werden automatisch nach einer begrenzten Zeit gelöscht und wieder andere werden von Google über längere Zeit gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen. Auch wenn Sie nicht in einem Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind, löschen.</p>
    <h3 class="adsimple-221148541">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert und dann gelöscht.</p>
    <p>Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren Browser so konfigurieren, dass Cookies von Google gelöscht bzw. deaktiviert werden. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
    <p>
      <a class="adsimple-221148541" href="https://support.google.com/chrome/answer/95647?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Internet Explorer: Löschen und Verwalten von Cookies</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Microsoft Edge: Löschen und Verwalten von Cookies</a>
    </p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht. Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter <a class="adsimple-221148541" href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer nofollow" >https://policies.google.com/privacy?hl=de.</a>
    </p>
    <h3 class="adsimple-221148541">Rechtsgrundlage</h3>
    <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene YouTube-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong class="adsimple-221148541">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong class="adsimple-221148541">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. YouTube setzt auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
    <h2 class="adsimple-221148541">Google Maps Datenschutzerklärung</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong class="adsimple-221148541">Google Maps Datenschutzerklärung Zusammenfassung</strong>
          <br />
          👥 Betroffene: Besucher der Website<br />
          🤝 Zweck: Optimierung unserer Serviceleistung<br />
          📓 Verarbeitete Daten: Daten wie etwa eingegebene Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten.<br />
          Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
          📅 Speicherdauer: abhängig von den gespeicherten Daten<br />
          ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
      </tr>
      </tbody>
    </table>
    <h3 class="adsimple-221148541">Was ist Google Maps?</h3>
    <p>Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google Maps können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden können.</p>
    <p>Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts mittels HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild. Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.</p>
    <h3 class="adsimple-221148541">Warum verwenden wir Google Maps auf unserer Website?</h3>
    <p>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern. Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.</p>
    <h3 class="adsimple-221148541">Welche Daten werden von Google Maps gespeichert?</h3>
    <p>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.</p>
    <p>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> NID<br />
      <strong class="adsimple-221148541">Wert:</strong> 188=h26c1Ktha7fCQTx8rXgLyATyITJ221148541-5<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu sammeln.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 6 Monaten</p>
    <p>
      <strong class="adsimple-221148541">Anmerkung:</strong> Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.</p>
    <h3 class="adsimple-221148541">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a class="adsimple-221148541" href="https://www.google.com/about/datacenters/inside/locations/?hl=de" target="_blank" rel="noopener noreferrer nofollow" >https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
    </p>
    <p>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm legt, bleiben die Daten ziemlich sicher trotzdem geschützt.</p>
    <p>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten löscht.</p>
    <h3 class="adsimple-221148541">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder ausschalten.</p>
    <p>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies immer etwas anders. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
    <p>
      <a class="adsimple-221148541" href="https://support.google.com/chrome/answer/95647?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Internet Explorer: Löschen und Verwalten von Cookies</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Microsoft Edge: Löschen und Verwalten von Cookies</a>
    </p>
    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
    <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen Dienstleister gibt.</p>
    <h3 class="adsimple-221148541">Rechtsgrundlage</h3>
    <p>Wenn Sie eingewilligt haben, dass Google Maps eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong class="adsimple-221148541"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Maps vorkommen kann, dar.</p>
    <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Maps zu verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong class="adsimple-221148541">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>.</p>
    <p>Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter <a class="adsimple-221148541" href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer nofollow" >https://policies.google.com/privacy?hl=de</a>.</p>
    <h2 class="adsimple-221148541">Zahlungsanbieter</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong class="adsimple-221148541">Zahlungsanbieter Datenschutzerklärung Zusammenfassung</strong>
          <br />
          👥 Betroffene: Besucher der Website<br />
          🤝 Zweck: Ermöglichung und Optimierung des Zahlungsvorgangs auf unserer Website<br />
          📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, Bankdaten (Kontonummer, Kreditkartennummer, Passwörter, TANs usw.), IP-Adresse und Vertragsdaten<br />
          Mehr Details dazu finden Sie beim jeweils eingesetzten Zahlungsanbieter-Tool.<br />
          📅 Speicherdauer: abhängig vom verwendeten Zahlungsanbieter<br />
          ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. b DSGVO (Erfüllung eines Vertrags)</td>
      </tr>
      </tbody>
    </table>
    <h3 class="adsimple-221148541">Was ist ein Zahlungsanbieter?</h3>
    <p>Wir verwenden auf unserer Website Online-Zahlungssysteme, die uns und Ihnen ein sicheres und reibungsloses Bezahlverfahren ermöglichen. Dabei können unter anderem auch personenbezogene Daten an den jeweiligen Zahlungsanbieter gesendet, gespeichert und dort verarbeitet werden. Bei Zahlungsanbietern handelt es sich um Online-Zahlungssysteme, die es Ihnen ermöglichen eine Bestellung über Online-Banking durchzuführen. Dabei wird die Zahlungsabwicklung durch den von Ihnen gewählten Zahlungsanbieter durchgeführt. Wir erhalten anschließend eine Information über die getätigte Zahlung. Diese Methode kann jeder User nutzen, der ein aktives Online-Banking-Konto mit PIN und TAN hat. Es gibt kaum noch Banken, die solche Zahlungsmethoden nicht anbieten bzw. akzeptieren.</p>
    <h3 class="adsimple-221148541">Warum verwenden wir Zahlungsanbieter auf unserer Website?</h3>
    <p>Wir wollen natürlich mit unserer Website und unserem eingebundenen Onlineshop das bestmögliche Service bietet, damit Sie sich auf unserer Seite wohl fühlen und unsere Angebote nutzen. Wir wissen, dass Ihre Zeit kostbar ist und speziell Zahlungsabwicklungen schnell und reibungslos funktionieren müssen. Aus diesen Gründen bieten wir Ihnen diverse Zahlungsanbieter an. Sie können Ihren bevorzugten Zahlungsanbieter wählen und so in gewohnter Manier bezahlen.</p>
    <h3 class="adsimple-221148541">Welche Daten werden verarbeitet?</h3>
    <p>Welche Daten genau verarbeitet werden, hängt natürlich von dem jeweiligen Zahlungsanbieter ab. Doch grundsätzlich werden Daten wie Name, Adresse, Bankdaten (Kontonummer, Kreditkartennummer, Passwörter, TANs usw.) gespeichert. Dabei handelt es sich um notwendige Daten, um überhaupt eine Transaktion durchführen zu können. Zudem können auch etwaige Vertragsdaten und Userdaten, wie zum Beispiel wann Sie unsere Website besuchen, für welche Inhalte Sie sich interessieren oder welche Unterseiten Sie anklicken, gespeichert werden. Auch Ihre IP-Adresse und Informationen zu Ihrem verwendeten Computer werden von den meisten Zahlungsanbietern gespeichert.</p>
    <p>Die Daten werden in der Regel auf den Servern der Zahlungsanbietern gespeichert und verarbeitet. Wir als Websitebetreiber erhalten diese Daten nicht. Wir werden nur darüber informiert, ob die Zahlung funktioniert hat oder nicht. Für Identitäts- und Bonitätsprüfungen kann es vorkommen, dass Zahlungsanbieter Daten an die entsprechende Stelle weiterleiten. Für alle Zahlungsgeschäfte gelten immer die Geschäfts- und Datenschutzgrundlagen des jeweiligen Anbieters. Schauen Sie sich daher bitte immer auch die Allgemeinen Geschäftsbedingen und die Datenschutzerklärung des Zahlungsanbieters an. Sie haben auch jederzeit das Recht beispielsweise Daten löschen oder korrigieren zu lassen. Bitte setzen Sie sich bezüglich Ihrer Rechte (Widerrufsrecht, Auskunftsrecht und Betroffenheitsrecht) mit dem jeweiligen Dienstanbieter in Verbindung.</p>
    <h3 class="adsimple-221148541">
      <span>Dauer der Datenverarbeitung</span>
    </h3>
    <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es wie zum Beispiel im Fall von Buchhaltung gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden. So bewahren wir zu einem Vertrag gehörige Buchungsbelege (Rechnungen, Vertragsurkunden, Kontoauszüge u.a.) 10 Jahre (§ 147 AO) sowie sonstige relevante Geschäftsunterlagen 6 Jahre (§ 247 HGB) nach Anfallen auf.</p>
    <h3 class="adsimple-221148541">Widerspruchsrecht</h3>
    <p>Sie haben immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Bei Fragen können Sie auch jederzeit Verantwortliche des verwendeten Zahlungsanbieter kontaktieren. Kontaktdaten finden Sie entweder in unserer spezifischen Datenschutzerklärung oder auf der Website des entsprechenden Zahlungsanbieters.</p>
    <p>Cookies, die Zahlungsanbieter für ihre Funktionen verwenden, können Sie in Ihrem Browser löschen, deaktivieren oder verwalten. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Bitte beachten Sie aber, dass dann eventuell der Zahlungsvorgang nicht mehr funktioniert.</p>
    <h3 class="adsimple-221148541">Rechtsgrundlage</h3>
    <p>Wir bieten also zur Abwicklung von vertraglichen bzw. rechtlichen Beziehungen <strong class="adsimple-221148541">(Art. 6 Abs. 1 lit. b DSGVO)</strong>
      <strong class="adsimple-221148541"> </strong>neben den herkömmlichen Bank-/Kreditinstitutionen auch andere Zahlungsdienstleister an. In den Datenschutzerklärungen der einzelnen Zahlungsanbietern (wie zum Beispiel <span class="adsimple-221148541">Amazon Payments</span>, <span class="adsimple-221148541" >Apple Pay</span> oder <span class="adsimple-221148541">Discover</span>) wird Ihnen ein genauer Überblick über die Datenverarbeitung und Datenspeicherung geboten. Zudem können Sie sich bei Fragen zu datenschutzrelevanten Themen stets an die Verantwortlichen richten.</p>
    <p>Informationen zu den speziellen Zahlungsanbietern erfahren Sie – sofern vorhanden – in den folgenden Abschnitten.</p>
    <h2 class="adsimple-221148541">Stripe Datenschutzerklärung</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong class="adsimple-221148541">Stripe Datenschutzerklärung Zusammenfassung</strong>
          <br />
          👥 Betroffene: Besucher der Website<br />
          🤝 Zweck: Optimierung des Zahlungsvorgangs auf unserer Website<br />
          📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, Bankdaten (Kontonummer, Kreditkartennummer, Passwörter, TANs usw.), IP-Adresse und Vertragsdaten<br />
          Mehr Details dazu finden Sie weiter unter in dieser Datenschutzerklärung<br />
          📅 Speicherdauer: Daten werden gespeichert, bis die Zusammenarbeit mit Stripe aufgelöst wird<br />
          ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. b DSGVO (Vertragsabwicklung), Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</td>
      </tr>
      </tbody>
    </table>
    <h3 class="adsimple-221148541">Was ist Stripe?</h3>
    <p>Wir verwenden auf unserer Website ein Zahlungstool des amerikanischen Technologieunternehmens und Online-Bezahldienstes Stripe. Für Kunden innerhalb von der EU ist Stripe Payments Europe (Europe Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland) verantwortlich. Das heißt, wenn Sie sich für Stripe als Zahlungsart entscheiden, wird Ihre Zahlung über Stripe Payments abgewickelt. Dabei werden Daten, die für den Zahlungsvorgang nötig sind, an Stripe weitergeleitet und gespeichert. In dieser Datenschutzerklärung geben wir Ihnen einen Überblick über diese Datenverarbeitung und Speicherung durch Stripe und erklären, warum wir Stripe auf unserer Website verwenden.</p>
    <p>Das Technologieunternehmen Stripe bietet Zahlungslösungen für Online-Bezahlungen an. Mit Stripe ist es möglich Kredit- und Debitkartenzahlungen in unserem Webshop zu akzeptieren. Stripe übernimmt den gesamten Zahlungsvorgang. Ein großer Vorteil von Stripe ist etwa, dass Sie während des Zahlungsvorgangs nie unsere Website bzw. den Shop verlassen müssen und die Zahlungsabwicklung sehr schnell erfolgt.</p>
    <h3 class="adsimple-221148541">Warum verwenden wir Stripe für unsere Website?</h3>
    <p>Wir wollen natürlich mit unserer Website und unserem eingebundenen Onlineshop das bestmögliche Service bietet, damit Sie sich auf unserer Seite wohl fühlen und unsere Angebote nutzen. Wir wissen, dass Ihre Zeit kostbar ist und daher speziell Zahlungsabwicklungen schnell und reibungslos funktionieren müssen. Neben unseren anderen Zahlungsanbietern haben wir mit Stripe einen Partner gefunden, der eine sichere und schnelle Zahlungsabwicklung gewährleistet.</p>
    <h3 class="adsimple-221148541">Welche Daten werden von Stripe gespeichert?</h3>
    <p>Wenn Sie sich für Stripe als Zahlungsart entscheiden, werden auch personenbezogene Daten von Ihnen an Stripe übermittelt und dort gespeichert. Dabei handelt es sich um Transaktionsdaten. Zu diesen Daten zählen etwa die Zahlungsmethode (also Kreditkarten- Debitkarten oder Kontonummer), Bankleitzahl, Währung, der Betrag und das Datum der Zahlung. Bei einer Transaktion kann weiters Ihr Name, Ihre E-Mail-Adresse, Ihre Rechnungs- oder Versandadresse und manchmal auch Ihr Transaktionsverlauf übermittelt werden. Diese Daten sind zur Authentifizierung nötig. Weiters kann Stripe zur Betrugsabwehr, der Finanzberichterstattung und um die eigenen Dienste vollständig anbieten zu können, auch neben technischen Daten zu Ihrem Gerät (wie IP-Adresse) Name, Adresse, Telefonnummer und Ihr Land erfassen.</p>
    <p>Stripe verkauft keine Ihrer Daten an unabhängige Dritte, wie etwa Marketingagenturen oder andere Unternehmen, die mit dem Stripe-Unternehmen nichts zu tun haben. Die Daten können aber etwa an interne Abteilungen, einer beschränkten Anzahl externer Stripe-Partner oder zur Einhaltung gesetzlicher Vorschriften weitergeleitet werden. Stripe verwendet zur Erfassung von Daten auch Cookies. Hier finden Sie eine Auswahl an Cookies, die Stripe während des Zahlungsprozesses setzen kann:</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> m<br />
      <strong class="adsimple-221148541">Wert:</strong> edd716e9-d28b-46f7-8a55-e05f1779e84e040456221148541-5<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Dieses Cookie erscheint, wenn Sie die Zahlungsmethode auswählen. Es speichert und erkennt, ob Sie über einen PC, ein Tablet oder ein Smartphone auf unsere Website zugreifen.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach 2 Jahren</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> __stripe_mid<br />
      <strong class="adsimple-221148541">Wert:</strong> fc30f52c-b006-4722-af61-a7419a5b8819875de9221148541-1<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Um eine Kreditkartentransaktion durchführen zu können, wird dieses Cookie benötigt. Dazu speichert das Cookie Ihre Sitzungs-ID.<br />
      <strong class="adsimple-221148541">Ablaufdatum:</strong> nach einem Jahr</p>
    <p>
      <strong class="adsimple-221148541">Name:</strong> __stripe_sid<br />
      <strong class="adsimple-221148541">Wert:</strong> 6fee719a-c67c-4ed2-b583-6a9a50895b122753fe<br />
      <strong class="adsimple-221148541">Verwendungszweck:</strong> Auch dieses Cookie speichert Ihre ID und wird für den Zahlungsprozess auf unserer Website durch Stripe verwendet.<br />
      <strong class="adsimple-221148541">Ablaufdatum</strong>: nach Ablauf der Sitzung</p>
    <h3 class="adsimple-221148541">Wie lange und wo werden die Daten gespeichert?</h3>
    <p>Personenbezogene Daten werden grundsätzlich für die Dauer der Diensterbringung gespeichert. Das heißt, die Daten werden so lange gespeichert, bis wir die Zusammenarbeit mit Stripe auflösen. Um allerdings die gesetzlichen und behördlichen Pflichten zu erfüllen kann Stripe auch über die Dauer der Diensterbringung personenbezogene Daten speichern. Da Stripe ein weltweit tätiges Unternehmen ist, können die Daten auch in jedem Land, wo Stripe Dienstleistungen anbietet, gespeichert werden. So können auch Daten außerhalb Ihres Landes, zum Beispiel in den USA gespeichert werden.</p>
    <h3 class="adsimple-221148541">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
    <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen Dienstleister gibt.</p>
    <p>Sie haben immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Bei Fragen können Sie auch jederzeit das Stripe-Team über <a class="adsimple-221148541" href="https://support.stripe.com/contact/email" target="_blank" rel="noopener noreferrer nofollow" >https://support.stripe.com/contact/email</a> kontaktieren.</p>
    <p>Cookies, die Stripe für ihre Funktionen verwenden, können Sie in Ihrem Browser löschen, deaktivieren oder verwalten. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Bitte beachten Sie aber, dass dann eventuell der Zahlungsvorgang nicht mehr funktioniert. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
    <p>
      <a class="adsimple-221148541" href="https://support.google.com/chrome/answer/95647?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Internet Explorer: Löschen und Verwalten von Cookies</a>
    </p>
    <p>
      <a class="adsimple-221148541" href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >Microsoft Edge: Löschen und Verwalten von Cookies</a>
    </p>
    <h3 class="adsimple-221148541">Rechtsgrundlage</h3>
    <p>Wir bieten also zur Abwicklung von vertraglichen bzw. rechtlichen Beziehungen <strong class="adsimple-221148541">(Art. 6 Abs. 1 lit. b DSGVO)</strong>
      <strong class="adsimple-221148541"> </strong>neben den herkömmlichen Bank-/Kreditinstitutionen auch den Zahlungsdienstleister Sofortüberweisung an. Der erfolgreiche Einsatz des Dienstes bedarf ferner Ihrer Einwilligung <strong class="adsimple-221148541">(Art. 6 Abs. 1 lit. a DSGVO)</strong>
      <strong class="adsimple-221148541"> </strong>, soweit für den Einsatz die Zulassung von Cookies notwendig ist.</p>
    <p>Wir haben Ihnen nun einen allgemeinen Überblick über die Verarbeitung und Speicherung der Daten durch Stripe gegeben. Wenn Sie noch mehr und noch genauere Informationen einholen wollen, dient Ihnen die ausführliche Stripe-Datenschutzerklärung unter <a class="adsimple-221148541" href="https://stripe.com/at/privacy?tid=221148541" target="_blank" rel="noopener noreferrer nofollow" >https://stripe.com/at/privacy</a> als gute Quelle.</p>
    <h2 class="adsimple-221148541">Social Media</h2>
    <table border="1" cellpadding="15">
      <tbody>
      <tr>
        <td>
          <strong class="adsimple-221148541">Social Media Datenschutzerklärung Zusammenfassung</strong>
          <br />
          👥 Betroffene: Besucher der Website<br />
          🤝 Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern, Interessenten u.a., Werbung<br />
          📓 Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
          Mehr Details dazu finden Sie beim jeweils eingesetzten Social-Media-Tool.<br />
          📅 Speicherdauer: abhängig von den verwendeten Social-Media-Plattformen<br />
          ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
      </tr>
      </tbody>
    </table>
    <h3 class="adsimple-221148541">Was ist Social Media?</h3>
    <p>Zusätzlich zu unserer Website sind wir auch in diversen Social-Media-Plattformen aktiv. Dabei können Daten von Usern verarbeitet werden, damit wir gezielt User, die sich für uns interessieren, über die sozialen Netzwerke ansprechen können. Darüber hinaus können auch Elemente einer Social-Media-Plattform direkt in unsere Website eingebettet sein. Das ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf unserer Website anklicken und direkt zu unserem Social-Media-Auftritt weitergeleitet werden. Als sogenannte Sozialen Medien oder Social Media werden Websites und Apps bezeichnet, über die angemeldete Mitglieder Inhalte produzieren, Inhalte offen oder in bestimmten Gruppen austauschen und sich mit anderen Mitgliedern vernetzen können.</p>
    <h3 class="adsimple-221148541">Warum nutzen wir Social Media?</h3>
    <p>Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten können wir unsere Produkte und Dienstleistungen Interessenten näherbringen. Die auf unserer Website eingebundenen Social-Media-Elemente helfen Ihnen, schnell und ohne Komplikationen zu unseren Social-Media-Inhalten wechseln können.</p>
    <p>Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert und verarbeitet werden, haben in erster Linie den Zweck, Webanalysen durchführen zu können. Ziel dieser Analysen ist es, genauere und personenbezogene Marketing- und Werbestrategien entwickeln zu können. Abhängig von Ihrem Verhalten auf einer Social-Media-Plattform, können mit Hilfe der ausgewerteten Daten, passende Rückschlüsse auf Ihre Interessen getroffen werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen auch möglich, Ihnen maßgeschneiderte Werbeanzeigen zu präsentieren. Meistens werden für diesen Zweck Cookies in Ihrem Browser gesetzt, die Daten zu Ihrem Nutzungsverhalten speichern.</p>
    <p>In der Regel sind wir und der Anbieter der Social-Media-Plattform sogenannte gemeinsame Verarbeiter im Sinne des Art. 26 DSGVO und arbeiten auf Grundlage einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist weiter unten bei der betroffenen Plattform wiedergegeben.</p>
    <p>Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen oder unserer eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können, da viele Social-Media-Kanäle, beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
    <h3 class="adsimple-221148541">Welche Daten werden verarbeitet?</h3>
    <p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen Anbieter der Social-Media-Plattform ab. Aber für gewöhnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wen Sie liken oder wem folgen, wann Sie welche Seiten besucht haben, Informationen zu Ihrem Gerät und Ihre IP-Adresse. Die meisten dieser Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein Profil bei dem besuchten Social-Media-Kanal haben und angemeldet sind, können Daten mit Ihrem Profil verknüpft werden.</p>
    <p>Alle Daten, die über eine Social-Media-Plattform erhoben werden, werden auch auf den Servern der Anbieter gespeichert. Somit haben auch nur die Anbieter Zugang zu den Daten und können Ihnen die passenden Auskünfte geben bzw. Änderungen vornehmen.</p>
    <p>Wenn Sie genau wissen wollen, welche Daten bei den Social-Media-Anbietern gespeichert und verarbeitet werden und wie sie der Datenverarbeitung widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des Unternehmens sorgfältig durchlesen. Auch wenn Sie zur Datenspeicherung und Datenverarbeitung Fragen haben oder entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich direkt an den Anbieter wenden.</p>
    <h3 class="adsimple-221148541">
      <span class="adsimple-221148541">Dauer der Datenverarbeitung</span>
    </h3>
    <p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie für den eigenen Zweck nicht mehr benötigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon innerhalb von zwei Tagen gelöscht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
    <h3 class="adsimple-221148541">Widerspruchsrecht</h3>
    <p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie eingebettete Social-Media-Elemente zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
    <p>Da bei Social-Media-Tools Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
    <h3 class="adsimple-221148541">Rechtsgrundlage</h3>
    <p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong class="adsimple-221148541">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong class="adsimple-221148541">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>

    <h2 id="facebook-pixel-datenschutzerklaerung" class="adsimple-111854996">Facebook-Pixel Datenschutzerklärung</h2>
    <p>Wir verwenden auf unserer Website das Facebook-Pixel von Facebook. Dafür haben wir einen Code auf unserer Webseite implementiert. Der Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine Ansammlung von Funktionen lädt, mit denen Facebook Ihre Userhandlungen verfolgen kann, sofern Sie über Facebook-Ads auf unsere Webseite gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer Webseite erwerben, wird das Facebook-Pixel ausgelöst und speichert Ihre Handlungen auf unserer Webseite in einem oder mehreren Cookies. Diese Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie IP-Adresse, User-ID) mit den Daten Ihres Facebook-Kontos abzugleichen. Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für uns anonym und nicht einsehbar und werden nur im Rahmen von Werbeanzeigenschaltungen nutzbar. Wenn Sie selbst Facebook-User sind und angemeldet sind, wird der Besuch unserer Webseite automatisch Ihrem Facebook-Benutzerkonto zugeordnet.</p>
    <p>Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche und Interessen abgestimmt werden. So bekommen Facebook-User (sofern sie personalisierte Werbung erlaubt haben) passende Werbung zu sehen. Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und eigenen Werbeanzeigen.</p>
    <p>Im Folgenden zeigen wir Ihnen jene Cookies, die durch das Einbinden von Facebook-Pixel auf einer Testseite gesetzt wurden. Bitte beachten Sie, dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf unserer Webseite werden unterschiedliche Cookies gesetzt.</p>
    <p>
      <strong class="adsimple-111854996">Name:</strong> _fbp<br />
      <strong class="adsimple-111854996">Wert:</strong> fb.1.1568287647279.257405483-6111854996-7<br />
      <strong class="adsimple-111854996">Verwendungszweck:</strong> Dieses Cookie verwendet Facebook, um Werbeprodukte anzuzeigen.<br />
      <strong class="adsimple-111854996">Ablaufdatum:</strong> nach 3 Monaten</p>
    <p>
      <strong class="adsimple-111854996">Name:</strong>fr<br />
      <strong class="adsimple-111854996">Wert:</strong> 0aPf312HOS5Pboo2r..Bdeiuf&#8230;1.0.Bdeiuf.<br />
      <strong class="adsimple-111854996">Verwendungszweck:</strong> Dieses Cookie wird verwendet, damit Facebook-Pixel auch ordentlich funktioniert.<br />
      <strong class="adsimple-111854996">Ablaufdatum:</strong> nach 3 Monaten</p>
    <p>
      <strong class="adsimple-111854996">Name:</strong> comment_author_50ae8267e2bdf1253ec1a5769f48e062111854996-3<br />
      <strong class="adsimple-111854996">Wert:</strong> Name des Autors<br />
      <strong class="adsimple-111854996">Verwendungszweck:</strong> Dieses Cookie speichert den Text und den Namen eines Users, der beispielsweise einen Kommentar hinterlässt.<br />
      <strong class="adsimple-111854996">Ablaufdatum:</strong> nach 12 Monaten</p>
    <p>
      <strong class="adsimple-111854996">Name:</strong> comment_author_url_50ae8267e2bdf1253ec1a5769f48e062<br />
      <strong class="adsimple-111854996">Wert:</strong> https%3A%2F%2Fwww.testseite…%2F (URL des Autors)<br />
      <strong class="adsimple-111854996">Verwendungszweck:</strong> Dieses Cookie speichert die URL der Website, die der User in einem Textfeld auf unserer Webseite eingibt.<br />
      <strong class="adsimple-111854996">Ablaufdatum:</strong> nach 12 Monaten</p>
    <p>
      <strong class="adsimple-111854996">Name:</strong> comment_author_email_50ae8267e2bdf1253ec1a5769f48e062<br />
      <strong class="adsimple-111854996">Wert:</strong> E-Mail-Adresse des Autors<br />
      <strong class="adsimple-111854996">Verwendungszweck:</strong> Dieses Cookie speichert die E-Mail-Adresse des Users, sofern er sie auf der Website bekannt gegeben hat.<br />
      <strong class="adsimple-111854996">Ablaufdatum:</strong> nach 12 Monaten</p>
    <p>
      <strong class="adsimple-111854996">Anmerkung:</strong>Die oben genannten Cookies beziehen sich auf ein individuelles Userverhalten. Speziell bei der Verwendung von Cookies sind Veränderungen bei Facebook nie auszuschließen.</p>
    <p>Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter <a class="adsimple-111854996" href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank" rel="noopener noreferrer">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a> selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf <a class="adsimple-111854996" href="http://www.youronlinechoices.com/de/praferenzmanagement/?tid=111854996" target="_blank" rel="noopener noreferrer">http://www.youronlinechoices.com/de/praferenzmanagement/</a> grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.</p>
    <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Facebook-Pixel. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus anderen Diensten von Facebook, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</p>
    <p>Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen, empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf <a class="adsimple-111854996" href="https://www.facebook.com/policy.php" target="_blank" rel="noopener noreferrer">https://www.facebook.com/policy.php</a>.</p>
  </v-card>
</template>

<script>
export default {
  name: "DataPrivacy2"
}
</script>

<style scoped>

</style>